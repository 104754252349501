import {graphql, useStaticQuery} from "gatsby";
import React, {useState} from "react";
import Header from "src/components/common/header";
import CommonHelmet from "src/components/common/helmet";
import Footer from "src/components/common/footer";
import FullScreenImageWithText from "src/components/common/fullscreen_image_with_text";
import ResponsiveGatsbyImage from "src/components/common/responsive_image";

export default function ProvisioningAppPage() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          provisioningAppPage {
            hero {
              alt
              image
              title
            }
            text
            app {
              image
              alt
            }
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "pages/provisioning_app"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 60)
            }
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.provisioningAppPage;
  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <div className="max-w-7xl relative">
        <Header heroStyle="dark" />
        <CommonHelmet />
        {/* Hero */}
        <FullScreenImageWithText
          image_alt={metadata.hero.alt}
          files={data.allFile.edges}
          image_name={metadata.hero.image}
          title={metadata.hero.title}
          style="dark"
        />
        <div className="p-6 md:p-8 lg:p-12 font-light flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-12">
          <div
            dangerouslySetInnerHTML={{__html: metadata.text}}
            className="md:w-2/3"
          />
          <ResponsiveGatsbyImage
            className="rounded-3xl"
            alt={metadata.app.alt}
            files={data.allFile.edges}
            image={metadata.app.image}
          />
        </div>
        <Footer />
      </div>
    </main>
  );
}
